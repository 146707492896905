import React from "react"

import AuthFormWrapper from "../auth/components/AuthFormWrapper"
import SignInForm from "../auth/sign-in/components/SignInForm"
import Seo from "../components/seo"

const SignInPage: React.FC = () => {
  return (
    <AuthFormWrapper title={"Sign in"}>
      <Seo title="Yoco - Sign in" />
      <SignInForm />
    </AuthFormWrapper>
  )
}

export default SignInPage
