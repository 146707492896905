import * as Yup from "yup"

import { validatePhoneNumber } from "../utils/mobileNumber"

export const validateNewPassword = Yup.string()
  .required("Password required")
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+=?|~])[A-Za-z\d!@#$%^&*()_+=?|~]{8,}$/,
    `
    Please include the following in your password:\n
    • At least one capital letter\n
    • At least one lowercase letter\n
    • At least one number\n
    • At least one special character\n
    • No less than 8 characters
    `
  )

export const validateEmail = Yup.string()
  .email("Invalid email address")
  .required("Email required")

export const validatePhone = Yup.string()
  .required("Phone number required")
  .test("is-valid-phone-number", "Enter a valid phone number", (value) =>
    validatePhoneNumber(value)
  )

export const validateFirstName = Yup.string()
  .matches(
    /^([^!@#$%^&*(),/?\\|[\]{}=+~<>0-9])+$/,
    "Your first name should not contain digits or special characters"
  )
  .required("First name required")

export const validateLastName = Yup.string()
  .required("Last name required")
  .matches(
    /^([^!@#$%^&*(),/?\\|[\]{}=+~<>0-9])+$/,
    "Your last name should not contain digits or special characters"
  )

export const validateIdentity = Yup.object().shape(
  {
    idNumber: Yup.string().when("passportNumber", {
      is: (passportNumber: string) =>
        !passportNumber || passportNumber.length === 0,
      then: Yup.string()
        .required("ID number or passport required")
        .length(13, "Your ID number must be 13 digits")
        .matches(/^\d+$/, "Your ID must only contain digits"),
      otherwise: Yup.string(),
    }),
    passportNumber: Yup.string().when("idNumber", {
      is: (idNumber: string) => !idNumber || idNumber.length === 0,
      then: Yup.string().required("ID number or passport required"),
      otherwise: Yup.string(),
    }),
  },
  [["idNumber", "passportNumber"]]
)

const MAX_PROFILE_PIC_SIZE_KB = 4000
const SUPPORTED_PROFILE_PICTURE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
]

export const validateProfilePicture = Yup.mixed()
  .notRequired()
  .test(
    "fileSize",
    `File Size must be less than ${MAX_PROFILE_PIC_SIZE_KB / 1000}Mb`,
    (value) => !value?.size || value?.size / 1000 <= MAX_PROFILE_PIC_SIZE_KB
  )
  .test(
    "fileType",
    "Unsupported File Format",
    (value) =>
      !value?.type || SUPPORTED_PROFILE_PICTURE_FORMATS.includes(value?.type)
  )

export const validateAddress = Yup.object({
  addressLine1: Yup.string().required("Street address required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("City required"),
  province: Yup.string().required("Province required"),
  country: Yup.string().required("Country required"),
  postalCode: Yup.string()
    .required("Postal code required")
    .matches(/^\d+$/, "A postal code may only contain digits"),
})

const pattern =
  "[0-9]{4}/[0-9]{6,7}/(06|07|08|09|10|12|21|22|23|24|25|26|30|31)"

export const validateBusinessRegistration = Yup.string()
  .required("Business registration number required")
  .matches(
    RegExp(`^${pattern}$`),
    "A business registration number must match the format YYYY/NNNNNN(N)/NN"
  )

export const validateVatNumber = Yup.string()
  .length(10, "VAT number must be 10 digits")
  .matches(RegExp(/^\d+$/), "A VAT number may only contain digits")
  .matches(RegExp(/^[4]{1}[0-9]{9}$/), "Invalid VAT number")
