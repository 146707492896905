import { Formik } from "formik"
import React from "react"
import * as Yup from "yup"

import { Input, Button, Password, LabeledLink } from "../../../components"
import { useSignIn, useNextUrl } from "../../hooks"
import { validateEmail } from "../../validationObjects"

const validationSchema = Yup.object({
  email: validateEmail,
  password: Yup.string().required("Password required"),
})

const SignInForm: React.FC = () => {
  const { signIn, loading } = useSignIn()
  const nextUrl = useNextUrl()

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          const success = await signIn(values)
          if (success) {
            nextUrl("/dashboard/")
          }
        } catch (err) {
          console.warn(err)
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} data-cy-test="sign-in-form">
          <Input
            cypressTestId="sign-in-email"
            placeholder="Email *"
            name="email"
            type="email"
          />
          <Password
            cypressTestId="sign-in-password"
            placeholder="Password *"
            name="password"
          />
          <Button
            cypressTestId="sign-in-submit"
            label="Sign In"
            disabled={isSubmitting || loading}
            loading={isSubmitting || loading}
            type="submit"
            color="#018567"
            textColor="white"
          />
          <LabeledLink
            cypressTestId="forgot-password-nav-link"
            label="Forgot your password?"
            linkText="Reset password"
            linkPath="/forgot-password/"
            padding="20px 0px 5px 0px"
          />
          <LabeledLink
            cypressTestId="sign-up-nav-link"
            label="Dont have an account?"
            linkText="Sign up"
            linkPath="/sign-up/"
            padding="5px 0px 20px 0px"
          />
        </form>
      )}
    </Formik>
  )
}

export default SignInForm
