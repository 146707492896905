import React from "react"
import styled from "styled-components"

import Logo from "../../components/Logo"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 100vw;
  padding: 50px 30px;
  margin: 0 auto;

  .headers {
    text-align: center;
  }
`

type Props = {
  title: string
}

const AuthFormWrapper: React.FC<Props> = ({ children, title }) => {
  return (
    <Wrapper>
      <div className="headers">
        <Logo
          src="/images/yoco-logo.svg"
          to="https://yoco.com/za"
          isExternal={true}
        />{" "}
        <Logo />
        <h3>{title}</h3>
      </div>
      {children}
    </Wrapper>
  )
}

export default AuthFormWrapper
